import React, { useEffect, useState } from "react";
import { Box, Typography, Fade } from "@mui/material";

const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
const minutes = Array.from({ length: 60 / 15 }, (_, i) => (i * 15).toString().padStart(2, "0") + ' Min');
const periods = ["AM", "PM"];

function CircularTimePicker({ items, selectedItem, onChange }) {
  const [transitionDirection, setTransitionDirection] = useState("");
  const selectedIndex = items.indexOf(selectedItem);

  const getPrevItem = () => items[(selectedIndex - 1 + items.length) % items.length];
  const getNextItem = () => items[(selectedIndex + 1) % items.length];

  const handlePrevClick = () => {
    setTransitionDirection("down");
    setTimeout(() => {
      onChange(getPrevItem());
      setTransitionDirection("");
    }, 200);
  };

  const handleNextClick = () => {
    setTransitionDirection("up");
    setTimeout(() => {
      onChange(getNextItem());
      setTransitionDirection("");
    }, 200);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" height={120}>
      {/* Previous item (always visible) */}
      <Typography
        variant="body1"
        style={{ opacity: 0.5, cursor: 'pointer' }}
        onClick={handlePrevClick}
      >
        {getPrevItem()}
      </Typography>

      {/* Current item with transition */}
      <Fade in={true} timeout={200}>
        <Typography
          variant="h4"
          style={{
            fontWeight: "500",
            margin: "10px 0",
            color: "#9C0D38", // Text color
            padding: "10px 15px",
            borderRadius: "20px", // Round effect
            border: "2px solid #C4C4C4", // Grey border
            fontSize: "1.3rem"
          }}
        >
          {selectedItem}
        </Typography>
      </Fade>

      {/* Next item (always visible) */}
      <Typography
        variant="body1"
        style={{ opacity: 0.5, cursor: 'pointer' }}
        onClick={handleNextClick}
      >
        {getNextItem()}
      </Typography>

    </Box>
  );
}

function TimePicker({ handleTimeSelection, slotType, timeFormat }) {
  const [selectedHour, setSelectedHour] = useState("12");
  const [selectedMinute, setSelectedMinute] = useState("00 min");
  const [selectedPeriod, setSelectedPeriod] = useState("AM");

  useEffect(() => {
    handleTimeSelection({ selectedHour, selectedMinute: selectedMinute.split(" ")[0], selectedPeriod })
  }, [selectedHour, selectedMinute, selectedPeriod, slotType])

  return (
    <>
      <Box display="flex" justifyContent="center" gap={2} fontSize={"1.5rem"} margin={"50px 0"}>
        <CircularTimePicker
          items={hours}
          selectedItem={selectedHour}
          onChange={setSelectedHour}
        />
        <CircularTimePicker
          items={minutes}
          selectedItem={selectedMinute}
          onChange={setSelectedMinute}
        />
        <CircularTimePicker
          items={periods}
          selectedItem={selectedPeriod}
          onChange={setSelectedPeriod}
        />
        <br />
        <br />
      </Box>
      <div style={{ textAlign: "center", fontWeight: "600" }}><Typography style={{ textAlign: "center", fontWeight: "600" }}>{timeFormat}</Typography></div>
    </>
  );
}

export default TimePicker;
