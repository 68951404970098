import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, CircularProgress, Typography } from '@mui/material';

const PaymentStatusPopup = ({ open, onClose, orderId, paymentStatus, setPaymentStatus }) => {
    const [pollingCount, setPollingCount] = useState(0);
    // const [paymentStatus, setPaymentStatus] = useState(status);
    console.log({paymentStatus})
    // useEffect(() => setPaymentStatus(status), [status])

    useEffect(() => {
        if (open) {
            const interval = setInterval(() => {
                if (pollingCount < 5 && paymentStatus === 'pending') {
                    checkPaymentStatus();
                    setPollingCount(prevCount => prevCount + 1);
                } else {
                    clearInterval(interval);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [open, pollingCount, paymentStatus]);

    const checkPaymentStatus = async () => {
        try {
            // Replace with your actual API call
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "orderId": orderId
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            const response = await fetch(process.env.REACT_APP_NODE_BASE_URL + "/api/check-order-status", requestOptions);
            const result = await response.json();

            if (result.data) {
                setPaymentStatus("success");
                onClose(); // Close the popup if the payment is not pending
            }
        } catch (error) {
            console.error('Error checking payment status:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Payment Status</DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                {paymentStatus === 'pending' && pollingCount < 5 ? (
                    <>
                        <CircularProgress />
                        <Typography>Please wait while we confirm your payment...</Typography>
                    </>
                ) : paymentStatus === 'failure' ? (
                    <Typography>Your payment has failed. Please try again.</Typography>
                ) : paymentStatus === 'success' ? (
                    <Typography>Your payment was successful!</Typography>
                ) : (
                    <Typography>
                        Your payment status is still pending. Please connect with a coordinator to verify the payment.
                    </Typography>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PaymentStatusPopup;
