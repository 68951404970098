import React, { useState, useCallback, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Typography,
  Box,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './BookingForm.css';
import { Helmet } from 'react-helmet'
import logo from "../../utilities/images/home_v2/logo.png"
import Nav from '../home_page_v2/Nav/index'
import Footer from '../home_page_v2/Footer/index'
import InputWithLabel from './inputWithLabel';
import BookingDetails from './bookingDetails';
import LabelComponent from './labelComponent';
// import ButtonGroup from './buttonGroup';
import CustomButtonGroup from './customButtonGroup';
import useRazorpay from "react-razorpay";
import PaymentStatusPopup from './PaymentStatusPopup';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import logo from "src/utilities/images/home_v2/logo.png"

const BookingForm = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [bookActive, setBookActive] = useState(false)
  const [slottype, setSlotType] = useState("Within 60 Mins")
  const [currentOrderId, setCurrentOrderId] = useState(null)
  const [openPopup, setOpenPopup] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [starttimeSelected, setStarttimeSelected] = useState(null)
  const [timeFormat, setTimeFormat] = useState(null)
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (slottype === "Within 60 Mins") {
      setStarttimeSelected(new Date().toISOString())
      setTimeFormat(null)
    }
  }, [slottype])

  const handleNameChange = (value) => {
    setName(value);
    console.log(value)
    // setNameError(!event.target.value);
  };

  const handlePhoneChange = (value) => {
    // const phoneValue = event.target.value;
    setPhone(value);
    // setPhoneError(!/^\d{10}$/.test(phoneValue)); // Validate phone number as 10 digits
  };

  // const handleSubmit = () => {
  //   if (!name) setNameError(true);
  //   if (!/^\d{10}$/.test(phone)) setPhoneError(true);
  //   if (!nameError && !phoneError && name && phone) {
  //     // Handle form submission
  //     alert('Booking Confirmed!');
  //   }
  // };
  const [Razorpay, isLoaded] = useRazorpay();
  const createOrder = async (params) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      "name": name,
      "mobile": phone,
      "slottype": slottype,
      "starttime": starttimeSelected,
      "city": city
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    let response = await fetch(process.env.REACT_APP_NODE_BASE_URL + "/api/create_order", requestOptions)
    response = response.text()
    return response;
  };

  const handlePayment = async () => {
    // console.log(await createOrder())
    if (!name) {
      setNameError("Name is mandatory")
    }
    if (!phone) {
      setPhoneError("Phone Number is mandatory")
    }
    if (!city) {
      setCityError(true);
    } else {
      setCityError(false)
    }
    if (nameError || phoneError || !name || !phone || !city) {
      return
    }
    const { data: { grandTotal, orderId, secret } } = JSON.parse(await createOrder());
    // console.log({ order, name, phone })
    setPaymentStatus("pending")
    setCurrentOrderId(orderId)
    const options: RazorpayOptions = {
      key: secret,
      order_id: orderId,
      amount: grandTotal,
      currency: "INR",
      name: name,
      description: slottype,
      image: "https://www.kynohealth.com/images/Kyno-footer-logo.png",
      handler: (res) => {
        if (res.razorpay_payment_id) {
          setPaymentStatus('pending');
        } else {
          setPaymentStatus('failure');
        }
        console.log(res);
        setOpenPopup(true);
      },
      modal: {
        ondismiss: function () {
          console.log("hello closing")
          setPaymentStatus('failure');
          setOpenPopup(true);
        },
      },
      prefill: {
        name: name,
        email: "aditya@kynohealth.com",
        contact: phone,
      },
      notes: {
        address: "HUAMI TECHNOLOGIES PRIVATE LIMITED, H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const resetPayment = () => {
    setPaymentStatus('pending');
    setOpenPopup(false);
  };

  const handleTimeSelection = ({ selectedHour, selectedMinute, selectedPeriod }) => {
    const isoString = convertToISOTime(`${selectedHour}:${selectedMinute} ${selectedPeriod}`, slottype);
    setTimeFormat(convertToCustomFormat(`${selectedHour}:${selectedMinute} ${selectedPeriod}`, slottype))
    console.log({ isoString })
    setStarttimeSelected(isoString)
  }

  useEffect(() => {
    if (paymentStatus === "success") {
      window.location.href = '/booking/confirmation?bookedSlot=' + starttimeSelected;
      // navigate('/booking/confirmation?bookedSlot=' + starttimeSelected);
    }
  }, [paymentStatus])

  // useEffect(() => {
  //   if (isLoaded) {
  //     handlePayment();
  //   }
  // }, [isLoaded, handlePayment])
  console.log({ paymentStatus })
  return (
    <div>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11084448289"></script>

        <title>Medical Consultation | Best Physician Doctor in Noida</title>
        <meta name="description" content="Looking for a skilled general physician in Noida? Our experienced doctors provide expert medical consultation services and personalized treatment." />
        <link rel="canonical" href="https://www.kynohealth.com/" />

        <meta property='og:image' content={logo} />
        <meta name="google-site-verification" content="EADXQCFm27CfkWKQlFSbbLUSV6Q1I-yh-8taT0qxR-s" />
      </Helmet>
      <Nav />
      <div className='booking_slot' style={{ width: '90%', maxWidth: "500px", margin: '50px auto', paddingTop: '50px 0' }}>
        {(<><Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className='title'>Personal Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputWithLabel error={nameError} setError={setNameError} handleInputChange={handleNameChange} label="Enter Name *" placeholder="Ex: Prachi Pancholi" type="name" />
            <InputWithLabel error={phoneError} setError={setPhoneError} handleInputChange={handlePhoneChange} label="Enter Phone Number *" placeholder="Ex: 9876543210" type="phone" />
            <FormControl fullWidth sx={{ marginBottom: '20px' }} error={cityError}>
              <LabelComponent text={"Select Your City *"} />
              <Select style={{ borderRadius: "10px" }} value={city} onChange={(e) => {
                setCity(e.target.value)
                setCityError(false)

              }} displayEmpty >
                <MenuItem value="">
                  <em>Choose your city</em>
                </MenuItem>
                <MenuItem value="Delhi">Delhi</MenuItem>
                <MenuItem value="Bangalore">Bangalore</MenuItem>
                <MenuItem value="Gurgaon">Gurgaon</MenuItem>
                <MenuItem value="Noida">Noida</MenuItem>
                <MenuItem value="Ghaziabad">Ghaziabad</MenuItem>
              </Select>
              {cityError && <FormHelperText sx={{ color: "#d32f2f" }}>City selection is mandatory.</FormHelperText>}
            </FormControl>
          </AccordionDetails>
        </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className='title'>Booking Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LabelComponent text="When you want to schedule you visit?" />
              <CustomButtonGroup setBookActive={setBookActive} setSlotType={setSlotType} slottype={slottype} />
              {bookActive && <BookingDetails handleTimeSelection={handleTimeSelection} slotType={slottype} timeFormat={timeFormat} />}
            </AccordionDetails>
          </Accordion>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{
                margin: "0 auto",
                marginTop: '20px',
                maxWidth: "343px",
                height: "46px",
                fontWeight: "600",
                padding: "12px 16px 12px 16px",
                gap: "8px",
                borderRadius: "10px",
                background: "#FB9F40"
              }}
              onClick={() => handlePayment()}
            >
              Confirm Booking
            </Button>
          </div>
          <PaymentStatusPopup open={openPopup} onClose={() => setOpenPopup(false)} orderId={currentOrderId} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} /></>)}
      </div>
      <Footer isHideSticky={true} />
    </div>
  );
};


function convertToISOTime(timeString, day = "today") {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  }
  if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }
  const currentDate = new Date();
  if (day === 'Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 1);
  } else if (day === 'Day after Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 2);
  }

  currentDate.setHours(hours, minutes, 0, 0);

  return currentDate.toISOString();
}

function convertToCustomFormat(timeString, day = 'today') {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  }
  if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  const currentDate = new Date();

  if (day === 'Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 1);
  } else if (day === 'Day after Tomorrow') {
    currentDate.setDate(currentDate.getDate() + 2);
  }

  let period;
  const hour = parseInt(hours, 10);

  if (modifier === 'AM') {
    period = hour < 12 ? 'morning' : 'afternoon';
  } else {
    if (hour < 17) {
      period = 'afternoon';
    } else if (hour < 20) {
      period = 'evening';
    } else {
      period = 'night';
    }
  }

  // Format the date part without changing the time zone
  const dayOfMonth = currentDate.getDate();
  const month = currentDate.toLocaleString('en-US', { month: 'long' });
  const suffix = dayOfMonth === 1 ? 'st' : dayOfMonth === 2 ? 'nd' : dayOfMonth === 3 ? 'rd' : 'th';

  const formattedTime = `${time.replace(':', ' : ')} ${modifier} in the ${period} of ${dayOfMonth}${suffix} ${month}`;

  return formattedTime;
}

// const timeString = "03:08 PM";
// const isoString = convertToISOTime(timeString);
// console.log(isoString);

// Example usage
// const utcDateString = '2024-08-15T17:45:37.649Z';
// const localFormattedDate = convertToLocalTime(utcDateString);

export default BookingForm;
