import React from 'react';
import { Typography } from '@mui/material';

const LabelComponent = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 'bold',
        color: '#424242',
        fontSize: '16px',
        marginBottom: '10px', // Add space between the label and the input field
      }}
    >
      {text}
    </Typography>
  );
};

export default LabelComponent;
