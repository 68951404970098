import React, { useState } from 'react';
import { Box, Button, createTheme, ThemeProvider } from '@mui/material';

// Define your theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#9C0D38', // Main color for active state
    },
    secondary: {
      main: '#000000', // Example secondary color for inactive state
      back: 'white',
      border:'#C4C4C4'
    }
  }
});

function ButtonGroup({setBookActive, setSlotType, slottype}) {
  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
  const [activeButton, setActiveButton] = useState(slottype);
  console.log({slottype})

  const handleButtonClick = (buttonName) => {
    if (buttonName === "Schedule visit") {
      setShowAdditionalButtons(true);
      setActiveButton("Tomorrow");
      setSlotType("Tomorrow")
    } else if (buttonName === "Within 60 Mins") {
      setShowAdditionalButtons(false);
      setActiveButton(buttonName);
      setSlotType(buttonName)
    } else {
      setActiveButton(buttonName);
      setSlotType(buttonName)
    }
    setBookActive(buttonName !== "Within 60 Mins")
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap={2} mb={2}>
        <Button
          variant="outlined"
          color={activeButton === "Within 60 Mins" ? "primary" : "secondary"}
          style={{
            borderRadius: "10px",
            borderColor: activeButton === "Within 60 Mins" ? theme.palette.primary.main : theme.palette.secondary.border,
              color: activeButton === "Within 60 Mins" ? "white" : theme.palette.secondary.main,
              background: activeButton === "Within 60 Mins" ? theme.palette.primary.main : theme.palette.secondary.back,
            lineHeight: "28px",
            width: "100%",
            fontSize: "14px",
            fontWeight: "600"
          }}
          onClick={() => handleButtonClick("Within 60 Mins")}
        >
          Within 60 Mins
        </Button>
        <Button
          variant="outlined"
          color={activeButton === "Schedule visit" ? "primary" : "secondary"}
          style={{
            borderRadius: "10px",
            borderColor: activeButton === "Schedule visit" || showAdditionalButtons ? theme.palette.primary.main : theme.palette.secondary.border,
              color: activeButton === "Schedule visit" || showAdditionalButtons ? "white" : theme.palette.secondary.main,
              background: activeButton === "Schedule visit" || showAdditionalButtons ? theme.palette.primary.main : theme.palette.secondary.back,
            lineHeight: "28px",
            width: "100%",
            fontSize: "14px",
            fontWeight: "600"
          }}
          onClick={() => handleButtonClick("Schedule visit")}
        >
          Schedule visit
        </Button>
      </Box>
      {showAdditionalButtons && (
        <Box display="flex" flexDirection="row" gap={2}>
          <Button
            variant="outlined"
            color={activeButton === "Tomorrow" ? "primary" : "secondary"}
            style={{
              borderRadius: "10px",
              borderColor: activeButton === "Tomorrow" ? theme.palette.primary.main : theme.palette.secondary.border,
              color: activeButton === "Tomorrow" ? "white" : theme.palette.secondary.main,
              background: activeButton === "Tomorrow" ? theme.palette.primary.main : theme.palette.secondary.back,
              lineHeight: "28px",
              width: "100%",
              fontSize: "14px",
              fontWeight: "600"
            }}
            onClick={() => handleButtonClick("Tomorrow")}
          >
            Tomorrow
          </Button>
          <Button
            variant="outlined"
            color={activeButton === "Day after Tomorrow" ? "primary" : "secondary"}
            style={{
              borderRadius: "10px",
              borderColor: activeButton === "Day after Tomorrow" ? theme.palette.primary.main : theme.palette.secondary.border,
              color: activeButton === "Day after Tomorrow" ? "white" : theme.palette.secondary.main,
              background: activeButton === "Day after Tomorrow" ? theme.palette.primary.main : theme.palette.secondary.back,
              lineHeight: "28px",
              width: "100%",
              fontSize: "14px",
              fontWeight: "600"
            }}
            onClick={() => handleButtonClick("Day after Tomorrow")}
          >
            Day after Tomorrow
          </Button>
        </Box>
      )}
    </Box>
  );
}

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <ButtonGroup />
//       </Box>
//     </ThemeProvider>
//   );
// }

export default ButtonGroup;
