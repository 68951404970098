import React from "react";
import Nav from "../Nav/index";
import Footer from "../Footer/index";

function index() {
  return (
    <>
      <Nav />
      <section>
        <div className="container">
            <h1 className="text-center mb-3">We're Just Around the Corner - 10+ Locations and Expanding!</h1>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16789.493890708953!2d77.043591!3d28.4582445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19f2db099e9f%3A0xdd47bf74c11f665a!2sDoctors%20Plaza!5e1!3m2!1sen!2sin!4v1725533954560!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Doctors Plaza, 78, Huda Market Rd,
                  Sector 15 Part 2, Sector 15, Gurugram, Haryana 122003
                </p>
                <a
                  href="https://maps.app.goo.gl/s724bcLR47ds3L4d7"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16753.777809434512!2d77.1977353!3d28.6823109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd8a574957e3%3A0xf0570d31ed897d56!2sDoctors%20Plaza!5e1!3m2!1sen!2sin!4v1725533999887!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 24/156, 1st Floor, Doctors Plaza,
                  above Bank Of Baroda, opp. HP Petrol Pump, Block 25, Shakti
                  Nagar, New Delhi, Delhi, 110007
                </p>
                <a
                  href="https://maps.app.goo.gl/6k4XDggpwEGufVX17"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4190.554735334762!2d77.06925!3d28.629500000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM3JzQ2LjIiTiA3N8KwMDQnMDkuMyJF!5e1!3m2!1sen!2sin!4v1725534065320!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> A-96 SHANKAR GARDEN, VIKAS PURI
                </p>
                <a
                  href="https://maps.app.goo.gl/LAwX2icqrZHv1WR66"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16765.409504631065!2d77.3764878!3d28.6095154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce52620118ad3%3A0x3b77fc33cb873002!2sNoida%20Multispeciality%20Hospital!5e1!3m2!1sen!2sin!4v1725534096762!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> M-31 Main Road Mamura, near R.K
                  Public School, Shramik Kunj, Sector 66, Noida, Uttar Pradesh
                  201301
                </p>
                <a
                  href="https://maps.app.goo.gl/wA7mYBiSAzVy6NdG8"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16774.17283632209!2d77.226929!3d28.554559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3bb3df5e021%3A0x9d07957be5cf9784!2sSama%20Hospital!5e1!3m2!1sen!2sin!4v1725534128215!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Sama Hospital, 8, Siri Fort Rd,
                  Sector 3, Sadiq Nagar, New Delhi, Delhi 110049
                </p>
                <a
                  href="https://maps.app.goo.gl/kexagNe6hVLGmXG5A"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4195.581776236592!2d77.0332538!3d28.503335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d198c74b751bf%3A0xd09a36b23d163118!2snewlife%20Multispeciality%20Hospital%20and%20Trauma%20Centre%2C%20Palam%20Vihar%2C%20Gurgaon!5e1!3m2!1sen!2sin!4v1725534154504!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> G23M+88J, Dharam Colony, Palam Vihar
                  Extension, Gurugram, Haryana 122017
                </p>
                <a
                  href="https://maps.app.goo.gl/QxEqQjWrWPaqvypE7"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4653.334271908235!2d77.6737234!3d12.923820099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae138e91d18e5f%3A0x3c2969401a383069!2sNELIVIGI%20MULTISPECIALITY%20AND%20UROLOGY%20HOSPITAL!5e1!3m2!1sen!2sin!4v1725534196243!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 449/434/09, Behind Kanti Sweets,
                  Bellandur Doddakannelli Road, Outer Ring Rd, Bellandur,
                  Bengaluru, Karnataka 560103
                </p>
                <a
                  href="https://maps.app.goo.gl/AsftVyF2Zr4Wzxby6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4653.336510140134!2d77.5739617!3d12.9237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3dd7901cc2f9%3A0x18b8a65ede823b57!2sSri%20SaiRam%20Hospital!5e1!3m2!1sen!2sin!4v1725534224497!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 729, 13th Main Rd, 7th Block,
                  Jayanagar, Bengaluru, Karnataka 560082
                </p>
                <a
                  href="https://maps.app.goo.gl/kaRQr3vEFPcNpk3a7"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4652.5287393194785!2d77.7171044!3d12.966972699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1223fc9dd1db%3A0x679620f7a86846b8!2sBrookefield%20Hospital%20-%20Your%20Friendly%20Neighbourhood%20Hospital!5e1!3m2!1sen!2sin!4v1725534251739!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Vijayalakshmi Square, 521/522, ITPL
                  Main Rd, Kundalahalli, Brookefield, Bengaluru, Karnataka
                  560037
                </p>
                <a
                  href="https://maps.app.goo.gl/xK7XVLFsxAtbBzyx9"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4652.342440427963!2d77.6363126!3d12.976932699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15ba453bafad%3A0x845de91fce2accf8!2sRevive%20Hospitals%20-%20Best%20Multispeciality%20Hospital%20in%20Indiranagar%20Bangalore!5e1!3m2!1sen!2sin!4v1725534280976!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 190, Paramahansa Yogananda Rd,
                  opposite Cauvery, Stage 2, Eshwara Layout, Indiranagar,
                  Bengaluru, Karnataka 560038
                </p>
                <a
                  href="https://maps.app.goo.gl/1WP3aroXMK9kygJb6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18607.19577812548!2d77.5712967!3d13.0059465!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17546a4d2afd%3A0xbd831b0bc6fe7979!2sISHA%20DIAGNOSTICS%20AND%20RESEARCH%20PRIVATE%20LIMITED!5e1!3m2!1sen!2sin!4v1725534318396!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 311, between 15th and 16th cross,
                  Sampige Rd, Malleshwaram, Bengaluru, Karnataka 560003
                </p>
                <a
                  href="https://maps.app.goo.gl/Uohifknm42iDFecg6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16797.797255567937!2d77.0683079!3d28.4059211!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d22689d145c33%3A0xb70c9f69827b3!2sEmerald%20Plaza!5e1!3m2!1sen!2sin!4v1725535038454!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Gurgaon office 419-20, Emerald
                  Plaza, Sector 65, Gurgaon
                </p>
                <a
                  href="https://maps.app.goo.gl/wB1fCbDJST4wpYXF8"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default index;
